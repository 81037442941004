import React, {
  useEffect,
  useState} from 'react';
import {createPortal} from 'react-dom';

import {NOTIFICATION_EVENTS} from '../../../services/analytics/pub-sub/constants';
import EventSubscriber from '../../../services/analytics/pub-sub/event-subscriber';

import NotificationWrapper from './notification-wrapper';

import styles from './styles.module.css';

const NotificationCenter = () => {
  const [notificationList, setNotificationList] = useState([]);
  const hideNotification = notificationId => {
    setNotificationList(
      list => list.filter(notification => notification.id !== notificationId)
    );
  };

  useEffect(() => {
    window.notificationSubscriber = window.notificationSubscriber || new EventSubscriber({
      [NOTIFICATION_EVENTS.SHOW]: notificationProps => {
        setNotificationList(list => ([
          ...list,
          {
            ...notificationProps,
            id: Date.now()
          }
        ]));
      },
      [NOTIFICATION_EVENTS.HIDE]: hideNotification
    });

    window.notificationDispatcher.subscribe(window.notificationSubscriber.getEvents());

    return () => {
      window.notificationSubscriber = null;
    };
  }, []);

  return createPortal(
    <div className={styles.root}>
      <div className={styles.notificationWrapper}>
        {notificationList.map(notification => (
          <NotificationWrapper
            {...notification}
            hideNotification={hideNotification}
            key={notification.id}
          />))}
      </div>
    </div>,
    document.getElementById('notification-center')
  );
};

export default NotificationCenter;
